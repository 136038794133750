import { LocationPermissionStates } from 'types/locationPermissionStates';

export const mapToLocationPermissionState = (
  permissionState: PermissionState
): LocationPermissionStates => {
  switch (permissionState) {
    case 'granted':
      return LocationPermissionStates.granted;
    case 'denied':
      return LocationPermissionStates.denied;
    case 'prompt':
      return LocationPermissionStates.prompt;
    default:
      console.warn('Unknown PermissionState, returning notInitiated');
      return LocationPermissionStates.notInitiated;
  }
};
