import React, { memo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import { removeDoubleSlashes } from 'lib/url';

export type elementSize = 'small' | 'medium';
export type buttonType = 'primary' | 'secondary';

type NavigationButton = PropsWithChildren<{
  href: string;
  size?: elementSize;
  buttonType: buttonType;
  onClick?: () => void;
}>;

export const NavigationButton = ({
  href,
  onClick,
  size = 'medium',
  buttonType,
  children,
}: NavigationButton) => {
  const classes = classNames(
    'shadow-lg shadow-black inline-flex justify-center items-center rounded-lg',
    {
      'px-6 py-2 text-sm': size === 'small',
      'px-8 py-4 text-normal': size === 'medium',
      'bg-white text-gray-600 hover:bg-gray-200': buttonType === 'secondary',
      'bg-primary hover:shadow-lg hover:underline': buttonType === 'primary',
    }
  );

  return (
    <Link className={classes} onClick={onClick} href={removeDoubleSlashes(href)} prefetch={false}>
      {children}
    </Link>
  );
};

export default memo(NavigationButton);
