import { ArrowCircleRightIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { TextBanner as TextBannerType } from '../../types/types';

export function TextBanner({ banner }: { banner: TextBannerType }) {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (window.sessionStorage) {
      setHidden(window.sessionStorage.getItem('show-text-banners') === '0');
    }
  }, []);

  const onClose = (e?: any) => {
    e.preventDefault();
    setHidden(true);
    if (window.sessionStorage) {
      window.sessionStorage.setItem('show-text-banners', '0');
    }
  };

  if (hidden) {
    return <></>;
  }

  return (
    <div className="py-2 sm:pb-5">
      <a href={banner.destinationUrl}>
        <div className="p-2 rounded-lg bg-orange shadow-lg">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <div className="flex rounded-lg ">
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-orange-darker focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={onClose}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
              <div
                className="ml-3 font-medium text-white"
                dangerouslySetInnerHTML={{ __html: banner.htmlText }}
              />
            </div>
            <div className="flex p-2 rounded-lg bg-orange hover:bg-orange-darker">
              <ArrowCircleRightIcon
                className="h-6 w-6 text-white hover:bg-orange-darker"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
