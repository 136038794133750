import { GeoFilter } from 'src/types/geolocation';
import { Taxon } from 'types/taxons';
import { useTranslation } from 'react-i18next';
import { ROOT_TAXON_ID } from 'constants/taxon';

interface GetHeaderProps {
  taxon?: Taxon;
  geoFilter: GeoFilter | null;
}

export const useProductsListHeader = (): {
  getProductListHeader: ({ taxon, geoFilter }: GetHeaderProps) => string;
} => {
  const { t } = useTranslation();

  const getTaxonName = (taxon?: Taxon): string => {
    if (!taxon) return t('app.ui.product_list.location.taxonFallback');
    if (taxon.parent && taxon.parent.id !== ROOT_TAXON_ID) return taxon.parent.name;

    return taxon.name;
  };

  const getProductListHeader = ({ taxon, geoFilter }: GetHeaderProps) => {
    const displayedTaxon = getTaxonName(taxon);
    // check whether to prefix listHeading with "in"
    const location = geoFilter?.listHeading
      ? `${t('app.ui.product_list.location.preposition')} ${geoFilter.listHeading}`
      : null;

    return location ? `${displayedTaxon} ${location}` : displayedTaxon;
  };

  return { getProductListHeader };
};
