import { Page } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformPageJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export async function fetchPage(slug: string): Promise<Page | null> {
  const url = resolveApiUrl(API_ENDPOINTS.GetPageBySlug(slug));
  const res = await doFetch(url);
  if (!res.ok) {
    console.error('fetchPage error:', url, res.status, res.statusText);
    return null;
  }

  const pageJson = await res.json();

  const page = transformPageJson(pageJson);

  if (page) captureTypeErrorIfInvalid('Page', page);

  return page;
}

export default fetchPage;
