import { Banner } from 'types/types';
import Image from 'next/legacy/image';
import Link from 'next/link';
import useResponsive from 'hooks/useResponsive';
import { trackBannerClick } from 'modules/tracking/events/events';

export function ImageBanners({
  bannerHeight,
  banners,
}: {
  bannerHeight: number;
  banners?: Banner[];
}) {
  const { isLg } = useResponsive();

  if (!banners || !banners.length) {
    return null;
  }

  const hasMultipleBanners = banners.length > 1;
  const width = hasMultipleBanners ? '595' : '1210';
  const gridSettings = hasMultipleBanners
    ? 'md:col-span-3 grid grid-cols-2 gap-2'
    : 'md:col-span-3 relative';
  const sizes = hasMultipleBanners ? '' : '100vw';

  const trackBanner = async (banner: Banner, index: number) => {
    await trackBannerClick({
      banner,
      width,
      height: bannerHeight.toString(),
      position: index + 1,
    });
  };

  return (
    <div className={gridSettings}>
      {banners.map((banner: Banner, index: number) => (
        <Link
          href={banner.url || '#'}
          passHref
          key={`banner-${banner.id}`}
          onClick={async () => {
            await trackBanner(banner, index);
          }}
        >
          <Image
            height={bannerHeight}
            width={width}
            src={isLg ? banner.imageSrc : banner.mobileSrc}
            alt={banner.imageAlt}
            quality={isLg ? 100 : 75}
            sizes={sizes}
            key={`${banner.imageSrc}-${index}`}
          />
        </Link>
      ))}
    </div>
  );
}

export default ImageBanners;
