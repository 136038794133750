import { HomePageData } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';
import transformHomePageData from 'lib/transforms/homepage';

export async function fetchHomepage(id: string = 'data'): Promise<HomePageData | null> {
  const url = resolveApiUrl(API_ENDPOINTS.GetHomePage(id));
  const res = await doFetch(url);

  if (!res.ok) {
    console.error('fetchHomepage error:', url, res.status, res.statusText);
    return null;
  }

  const homepage = await res.json();

  return transformHomePageData(homepage);
}

export default fetchHomepage;
