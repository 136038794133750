import { useCallback, useEffect, useState } from 'react';
import { Coordinates } from 'types/geolocation';
import { Nullable, PartialWithRequiredKeys } from 'types/common';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  setLocationPermission,
  getNeedsUserRequest,
  getIsNotInitiated,
  getHasUserPermissionPrompt,
} from 'redux/geolocation/geolocation.slice';
import { LocationPermissionStates } from 'types/locationPermissionStates';
import { mapToLocationPermissionState } from 'lib/navigator/permissions/mapBrowserNavigationPermissions';
import { initialClientCoordinates } from 'constants/geolocation';
import useLocationPermissionObserver from 'hooks/location/useLocationPermissionObserver';

interface useGeoLocationState extends Coordinates {
  loaded: boolean;
}

interface updateGeoLocationParams {
  coords: PartialWithRequiredKeys<GeolocationCoordinates, 'longitude' | 'latitude'>;
}

export default function useGeoLocation(): {
  latitude: Nullable<number>;
  longitude: Nullable<number>;
  loaded: boolean;
} {
  const dispatch = useAppDispatch();
  const needsUserRequest = useAppSelector(getNeedsUserRequest);
  const hasStatePrompt = useAppSelector(getHasUserPermissionPrompt);
  const isNotInitiated = useAppSelector(getIsNotInitiated);
  const [state, setState] = useState<useGeoLocationState>({
    ...initialClientCoordinates,
    loaded: false,
  });
  const [isForcingPermissionViaPrompt, setIsForcingPermissionViaPrompt] = useState<boolean>(false);

  useLocationPermissionObserver({
    onPermissionGranted: () => {
      if (isForcingPermissionViaPrompt) {
        setIsForcingPermissionViaPrompt(false);
        return;
      }

      navigator.geolocation.getCurrentPosition(updateGeoLocation, onError);
    },
    onPermissionDenied: () => {
      if (isForcingPermissionViaPrompt) {
        setIsForcingPermissionViaPrompt(false);
      }
    },
    onPermissionPrompt: () => {
      setIsForcingPermissionViaPrompt(true);
      navigator.geolocation.getCurrentPosition(updateGeoLocation, onError);
    },
  });

  const updateGeoLocation = useCallback(
    ({ coords }: updateGeoLocationParams) => {
      const { latitude, longitude } = coords;

      setState({ latitude, longitude, loaded: true });

      if (hasStatePrompt) {
        dispatch(setLocationPermission(LocationPermissionStates.granted));
      }
    },
    [hasStatePrompt, dispatch]
  );

  const onError = useCallback(
    (error: GeolocationPositionError) => {
      console.warn('Browser Geolocation Error: ', error.message, error);

      if (error.code === error.PERMISSION_DENIED) {
        dispatch(setLocationPermission(LocationPermissionStates.denied));
      }
    },

    [dispatch]
  );

  useEffect(() => {
    if (!needsUserRequest || !navigator || !navigator.permissions) {
      return;
    }

    navigator.permissions
      .query({ name: 'geolocation' })
      .then((permissionStatus: PermissionStatus) => {
        dispatch(setLocationPermission(mapToLocationPermissionState(permissionStatus.state)));
      });
  }, [dispatch, needsUserRequest]);

  useEffect(() => {
    if (isNotInitiated) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus: PermissionStatus) => {
          dispatch(setLocationPermission(mapToLocationPermissionState(permissionStatus.state)));

          if (!permissionStatus.onchange) {
            permissionStatus.onchange = () => {
              dispatch(setLocationPermission(mapToLocationPermissionState(permissionStatus.state)));
            };
          }
        });
    }

    return () => {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus: PermissionStatus) => {
          permissionStatus.onchange = null;
        });
    };
  }, [dispatch, isNotInitiated]);

  return { latitude: state.latitude, longitude: state.longitude, loaded: state.loaded };
}
