import { useCallback, useEffect, useRef, useState } from 'react';
import useStore from 'store';
import useSession from 'hooks/useSession';
import { tracker } from 'modules/tracking';
import PageTag from 'modules/tracking/tags/PageTag';
import isEqual from 'lodash.isequal';

interface JobProps {
  tag: PageTag;
  callback: Function | undefined;
}

export interface TrackPage {
  (tag: PageTag, callback?: Function): void;
}

export function usePageTracking(): {
  trackPage: TrackPage;
} {
  const [customer] = useStore('customer');
  const [unprocessedJobs, setUnprocessedJobs] = useState<JobProps[]>([]);
  const { status } = useSession();
  const lastPageType = useRef<PageTag | null>(null);

  const trackPage = useCallback((tag: PageTag, callback?: Function) => {
    if (isEqual(lastPageType.current, tag)) {
      return;
    }

    lastPageType.current = tag;
    setUnprocessedJobs((prevTags) => [...prevTags, { tag, callback }]);
  }, []);

  const publishTag = useCallback(
    async ({ tagToPublish }: { tagToPublish: PageTag }) => {
      tagToPublish.addCustomer(customer);

      await tracker.publish(tagToPublish);
    },
    [customer]
  );

  useEffect(() => {
    // 1. We only want to send events if there are events to send.
    // 2. We can only determine if we have a logged in customer if the session has finished loading.
    const shouldProcessJob = unprocessedJobs.length > 0 && status !== 'loading';
    if (!shouldProcessJob) {
      return;
    }

    unprocessedJobs.forEach(async (job: JobProps): Promise<any> => {
      await publishTag({ tagToPublish: job.tag });
      if (job.callback) {
        return await job.callback();
      }
    });

    setUnprocessedJobs([]);
  }, [publishTag, status, unprocessedJobs]);

  return { trackPage };
}

export default usePageTracking;
