import { PAGINATION_REQUEST_DEFAULTS } from 'constants/pagination';
import { Page, PaginatedData, PaginationQuery } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformPageJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchPages({
  page = PAGINATION_REQUEST_DEFAULTS.page,
  itemsPerPage = PAGINATION_REQUEST_DEFAULTS.itemsPerPage,
}: PaginationQuery<{}>): Promise<null | PaginatedData<Page[]>> {
  const query = {
    page,
    itemsPerPage,
  };

  const endpoint = resolveApiUrl(API_ENDPOINTS.GetPages);

  const res = await doFetch(endpoint, {}, query);

  if (!res.ok) {
    throw new Error(
      `Endpoint ${endpoint} responded with an unknown response, responseCode ${res.status}`
    );
  }

  const pagesJson = await res.json();

  const pages: Page[] = pagesJson.map(transformPageJson).filter(Boolean);

  pages.forEach((r: Page) => captureTypeErrorIfInvalid('Page', r));
  return {
    page,
    itemsPerPage,
    data: pages,
  };
}
