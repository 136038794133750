import { AppThunk } from 'redux/appStore';
import pageApi from 'api/page';
import {
  setBanners,
  setHighlightedProducts,
  setHomePageLoadingStatus,
  setInYourAreaStatus,
  setMainDeal,
  setMainProducts,
  setNewProducts,
  setPopularProducts,
  setProductsInYourArea,
} from 'redux/homePage/homePage.slice';
import productApi from 'api/product';
import { HOMEPAGE_NUM_LOCATION_BASED_PRODUCTS } from 'constants/product';
import { Banner, Banners, PaginationQuery, ProductTaxonLocationQuery } from 'types/types';
import { Coordinates } from 'types/geolocation';
import { Loading } from 'constants/common';

export const fetchHomePage = (): AppThunk => async (dispatch) => {
  dispatch(setHomePageLoadingStatus(Loading.PENDING));

  try {
    const result = await pageApi.fetchHomePage();
    if (result?.mainProducts) dispatch(setMainProducts(result.mainProducts));
    if (result?.mainDeal) dispatch(setMainDeal(result.mainDeal));
    if (result?.newProducts) dispatch(setNewProducts(result.newProducts));
    if (result?.highlightedProducts) dispatch(setHighlightedProducts(result.highlightedProducts));
    if (result?.populairProducts) dispatch(setPopularProducts(result.populairProducts));

    if (result?.banners) {
      const bannerMap = new Banners(result.banners);
      const primary = [bannerMap.getBannerForLocation('homepage_main_1') as Banner | null].filter(
        Boolean
      ) as Banner[];
      const secondary = [
        bannerMap.getBannerForLocation('homepage_main_2') as Banner | null,
        bannerMap.getBannerForLocation('homepage_main_3') as Banner | null,
      ].filter(Boolean) as Banner[];

      dispatch(setBanners({ primary, secondary }));
      dispatch(setHomePageLoadingStatus(Loading.SUCCEEDED));
    }
  } catch (e) {
    dispatch(setHomePageLoadingStatus(Loading.FAILED));
  }
};

export const fetchProductsInYourArea =
  ({ latitude, longitude }: Coordinates): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setInYourAreaStatus(Loading.PENDING));
      const response = await productApi.fetchProductsByLocation({
        itemsPerPage: HOMEPAGE_NUM_LOCATION_BASED_PRODUCTS,
        page: 1,
        latitude,
        longitude,
      } as PaginationQuery<ProductTaxonLocationQuery>);
      if (response) {
        dispatch(setInYourAreaStatus(Loading.SUCCEEDED));
        dispatch(setProductsInYourArea(response));
      } else {
        dispatch(setInYourAreaStatus(Loading.IDLE));
      }
    } catch (e) {
      dispatch(setInYourAreaStatus(Loading.FAILED));
    }
  };
