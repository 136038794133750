import fetchHomePage from 'api/page/fetchHomePage';
import fetchPage from 'api/page/fetchPage';
import fetchPages from 'api/page/fetchPages';

const pageApi = {
  fetchPage,
  fetchPages,
  fetchHomePage,
};

export default pageApi;
