import AbstractTag from './AbstractTag';
import { TrackingProduct, TrackingPromoImpression, TrackingValue } from 'types/tracking';
import { Customer } from 'types/types';

export default class PageTag extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'page');
  }

  set pageType(value: string) {
    this.addProp('page_type', value);
  }

  set pageCategory(value: string) {
    this.addProp('page_category', value);
  }

  addImpressions(value: TrackingProduct[]): this {
    return this.addProp('product_impression', value);
  }

  addProductDetails(value: TrackingProduct): this {
    return this.addProp('product_detail', [value]);
  }

  addPromoImpressions(value: TrackingPromoImpression[]): this {
    return this.addProp('promo_impression', value);
  }

  addUserId(value: string | null): this {
    return this.addProp('user_id', value);
  }

  addProfileGigyaId(value: string | null): this {
    return this.addProp('profile_gigyaid', value);
  }

  addCustomer(customer: Customer | null): this {
    this.addUserId(customer?.customerId?.toString() || null);
    return this.addProfileGigyaId(customer?.gigyaUid || null);
  }

  getPageType(): TrackingValue | null {
    return this.getData().page_type;
  }
}
