import { createContext, memo, useEffect, useRef } from 'react';
import useGeoLocation from 'hooks/location/useGeoLocation';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getClientLocation,
  selectGeoFilter,
  selectGeolocationSlice,
  setClientLocation,
  setGeoFilter,
} from 'redux/geolocation/geolocation.slice';
import areCoordinatesApproximatelyEqual from 'lib/geolocation/areCoordinatesApproximatelyEqual';
import { useRouter } from 'next/router';
import { getGeoCities } from 'redux/geolocation/geoProducts.slice';
import { getNewAsPath } from 'lib/router/asPath/routeWithLocationHelper';
import {
  CITY_GEO_FILTER_COOKIE_MAX_AGE,
  CITY_GEO_FILTER_COOKIE_NAME,
  GEOFILTER_TYPE,
} from 'constants/geolocation';
import { createGeoFilter } from 'lib/geolocation/mapToGeoFilter';
import { setInYourAreaStatus } from 'redux/homePage/homePage.slice';
import { Loading } from 'constants/common';
import { GeoFilter } from 'src/types/geolocation';
import isEqual from 'lodash.isequal';
import { encodeGeoFilterForCookie } from 'lib/geolocation/cityGeoFilter';
import { useCookies } from 'react-cookie';
import { resetAndFetchProducts } from 'redux/products/products.thunks';

const GeoLocationContext = () => {
  const router = useRouter();
  const [, setCookie] = useCookies();
  const dispatch = useAppDispatch();
  const geolocation = useAppSelector(selectGeolocationSlice);
  const clientCoordinates = useAppSelector(getClientLocation);
  const selectedGeoFilter = useAppSelector(selectGeoFilter);
  const geoCities = useAppSelector(getGeoCities);
  const previousSelectedGeoFilter = useRef<GeoFilter | null>(selectedGeoFilter);

  useEffect(() => {
    if (isEqual(selectedGeoFilter, previousSelectedGeoFilter.current)) {
      return;
    }

    previousSelectedGeoFilter.current = selectedGeoFilter;

    if (selectedGeoFilter?.type === GEOFILTER_TYPE.IP || geoCities.length < 1) {
      return;
    }

    const newAsPath = getNewAsPath({
      routerRoute: router.route,
      routerQuery: router.query,
      selectedGeoFilter: selectedGeoFilter,
      availableGeoCities: geoCities,
      shouldAddLocation: true,
    });

    if (newAsPath === router.asPath) {
      return;
    }

    router.push(newAsPath, undefined, { shallow: true });
  }, [selectedGeoFilter, geoCities, router]);

  const { latitude, longitude, loaded } = useGeoLocation();

  const GeoLocationContext = createContext({ ...geolocation });

  useEffect(() => {
    if (!loaded) return;
    // only dispatch the update for different coordinates
    if (!areCoordinatesApproximatelyEqual(clientCoordinates, { latitude, longitude })) {
      // keeps client location up-to-date with data from geolocation API
      dispatch(setClientLocation({ latitude, longitude }));
    }
    // set the gps ready flag if not set yet + only set it when we have coordinates
  }, [latitude, longitude, loaded, dispatch]);

  useEffect(() => {
    if (
      !selectedGeoFilter ||
      selectedGeoFilter.type !== GEOFILTER_TYPE.GPS ||
      !clientCoordinates.latitude ||
      !clientCoordinates.longitude
    ) {
      dispatch(setInYourAreaStatus(Loading.IDLE));
      return;
    }

    if (areCoordinatesApproximatelyEqual(clientCoordinates, selectedGeoFilter.query)) {
      return;
    }

    const newFilter = createGeoFilter({
      label: selectedGeoFilter.label,
      coordinates: clientCoordinates,
      type: GEOFILTER_TYPE.GPS,
      listHeading: selectedGeoFilter.listHeading,
      city: selectedGeoFilter.city,
    });

    dispatch(setGeoFilter(newFilter));
    dispatch(setInYourAreaStatus(Loading.IDLE));

    setCookie(CITY_GEO_FILTER_COOKIE_NAME, encodeGeoFilterForCookie(newFilter), {
      path: '/',
      maxAge: CITY_GEO_FILTER_COOKIE_MAX_AGE,
    });

    dispatch(resetAndFetchProducts());
  }, [selectedGeoFilter, clientCoordinates, dispatch]);

  return <GeoLocationContext.Provider value={geolocation} />;
};

export default memo(GeoLocationContext);
