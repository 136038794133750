export const MAPS_MARKER_PATH = '/img/maps-marker.png';

export const MAPS_MARKER_WIDTH = 24;

export const MAPS_MARKET_HEIGHT = 35;

export const HOMEPAGE_BANNER_HEIGHT_DESKTOP = 378;
export const HOMEPAGE_BANNER_HEIGHT_MOBILE = 756;

export const CATEGORY_BANNER_HEIGHT_DESKTOP = 320;
export const CATEGORY_BANNER_HEIGHT_MOBILE = 640;
