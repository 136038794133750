import { useEffect } from 'react';

import { useStore } from 'store';
import { useSession } from 'hooks/useSession';
import { useCustomer } from 'hooks/api/useCustomer';
import { handleSubscribeToNewsletter } from 'lib/handleSubscribeToNewsletter';

export const useNewsletter = () => {
  const { token } = useSession();
  const { customer, refreshCustomer } = useCustomer();
  const [_, setHasShownNewsletterPopup] = useStore('hasShownNewsletterPopup');

  const isCustomerSubscribed = (customer && customer.subscribedToNewsletter) || false;

  useEffect(() => {
    if (!token) return;

    void refreshCustomer(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function subscribeToNewsletter(email: string, location: string) {
    if (customer?.subscribedToNewsletter) return;

    await handleSubscribeToNewsletter(email, location, location);
    await setHasShownNewsletterPopup(true);

    if (token && token.length > 0) {
      await refreshCustomer(token);
    }
  }

  return {
    subscribeToNewsletter,
    isCustomerSubscribed,
  };
};
