import useStore from 'store';
import userApi from 'api/user';

export function useCustomer() {
  const [customer, setCustomer] = useStore('customer');

  async function refreshCustomer(token: string) {
    const customer = await userApi.fetchAccount(token);
    return setCustomer(customer);
  }

  return {
    customer,
    setCustomer,
    refreshCustomer: refreshCustomer,
  };
}
