import PageTag from '../PageTag';
import { TrackingPageType, TrackingProduct, TrackingPromoImpression } from 'types/tracking';
import { OrderSummary } from 'types/types';
import { TRACKING_CHECKOUT_STEP } from 'constants/enums';

export class PageTagFactory {
  static unknownPage(): PageTag {
    return this.createPageTag(TrackingPageType.unknown);
  }

  static searchPage(): PageTag {
    const tag = this.createPageTag(TrackingPageType.search);
    tag.addPromoImpressions([]);
    return tag;
  }

  static listingPage({ categoryName }: { categoryName: string | null }): PageTag {
    const tag = this.createPageTag(TrackingPageType.listing);
    tag.addProp('platform_version', 'new');
    tag.addProp('category_name', categoryName);

    return tag;
  }

  static mapsPage({ categoryName }: { categoryName: string | null }): PageTag {
    const tag = this.createPageTag(TrackingPageType.maps);
    tag.addProp('platform_version', 'new');
    tag.addProp('category_name', categoryName);

    return tag;
  }

  static jumboLandingPage({ promocode }: { promocode: string }): PageTag {
    const tag = this.createPageTag(TrackingPageType.jumboLanding);

    tag.addProp('coupon', promocode);

    return tag;
  }

  static homePage(props: {
    impressions: TrackingProduct[];
    promoImpressions: TrackingPromoImpression[];
  }): PageTag {
    const tag = this.createPageTag(TrackingPageType.home);
    tag.addImpressions(props.impressions);
    tag.addPromoImpressions(props.promoImpressions);
    return tag;
  }

  static productPage({
    product,
    impressions,
  }: {
    product: TrackingProduct;
    impressions: TrackingProduct[];
  }): PageTag {
    const tag = this.createPageTag(TrackingPageType.product);
    tag.pageCategory = 'product';
    tag.addImpressions(impressions);
    tag.addProductDetails(product);
    return tag;
  }

  static newsletterSubscribePage(): PageTag {
    return this.createPageTag(TrackingPageType.subscribe);
  }

  static newsletterUnsubscribePage(): PageTag {
    return this.createPageTag(TrackingPageType.unsubscribe);
  }

  static newsletterSubscriptionConfirmedPage(): PageTag {
    return this.createPageTag(TrackingPageType.subscribed);
  }

  static extraatjeVoorJouPage({ orderId }: { orderId: string | null }): PageTag {
    const tag = this.createPageTag(TrackingPageType.extraatjeVoorJou);
    tag.addProp('order_id', orderId);

    return tag;
  }

  static blogPage(): PageTag {
    return this.createPageTag(TrackingPageType.blog);
  }

  static faqPage(): PageTag {
    return this.createPageTag(TrackingPageType.faq);
  }

  static loginPage(): PageTag {
    return this.createPageTag(TrackingPageType.login);
  }

  static accountPage(): PageTag {
    return this.createPageTag(TrackingPageType.account);
  }

  static checkoutPage({
    step,
    products,
  }: {
    step: TRACKING_CHECKOUT_STEP;
    products: TrackingProduct[];
  }): PageTag {
    const tag = this.createPageTag(TrackingPageType.checkout);
    tag.pageCategory = 'checkout';
    tag.addProp('product', products);
    tag.addProp('form_name', 'checkout');
    tag.addProp('form_step', step);

    switch (step) {
      case TRACKING_CHECKOUT_STEP.cartOverview:
        tag.addProp('form_step_name', 'configure');
        break;

      case TRACKING_CHECKOUT_STEP.address:
        tag.addProp('form_step_name', 'checkout_address');
        break;

      case TRACKING_CHECKOUT_STEP.payment:
        tag.addProp('form_step_name', 'payment_method');
        break;
    }

    return tag;
  }

  static purchasePage({ order }: { order: OrderSummary }): PageTag {
    const tag = this.createPageTag(TrackingPageType.purchase);
    tag.pageCategory = 'purchase';
    tag.addProp('form_step_name', 'checkout_payment');
    tag.addProp('form_step', TRACKING_CHECKOUT_STEP.thankYou);
    tag.addOrderSummary(order);

    return tag;
  }

  private static createPageTag(
    pageType: TrackingPageType,
    props: Record<string, string | number> = {}
  ) {
    const tag = new PageTag();
    tag.pageType = pageType;

    Object.keys(props).forEach((key) => tag.addProp(key, props[key]));

    return tag;
  }
}

export default PageTagFactory;
